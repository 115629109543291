
import Vue from "vue";
import { DateTime } from "luxon";
import { VMenu, VTextField, VDatePicker } from "vuetify/lib";

export default Vue.extend({
  name: "MonthPicker",
  components: { VMenu, VTextField, VDatePicker },
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    format: {
      type: String,
      required: false,
      default: "yyyy年MM月"
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    flat: {
      type: Boolean,
      required: false,
      default: false
    },
    soloInverted: {
      type: Boolean,
      required: false,
      default: false
    },
    solo: {
      type: Boolean,
      required: false,
      default: false
    },
    prependInnerIcon: {
      type: String,
      required: false,
      default: undefined
    },
    filled: {
      type: Boolean,
      required: false,
      default: false
    },
    noTitle: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isMenu: false
    };
  },
  computed: {
    modelValue: {
      get(): string | null {
        return this.value;
      },
      set(newValue: string | null) {
        this.$emit("input", newValue);
      }
    },
    formatValue: {
      get(): string | null {
        return this.modelValue
          ? DateTime.fromFormat(this.modelValue, "yyyy-MM").toFormat(
              this.format
            )
          : null;
      }
    }
  }
});
