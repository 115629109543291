
import { AttendanceAlertRequest } from "@/models/alert/AlertRequest";
import { PetitionAlert, PetitionAlertType } from "@/models/alert/AlertResponse";
import Vue from "vue";

const header: { text: string; value: PetitionAlertType }[] = [
  { text: "対象日", value: "flowDate" },
  { text: "最終承認日", value: "latestApprovalDate" },
  { text: "承認種類", value: "approvalType" },
  { text: "承認状態", value: "latestApprovalState" },
  { text: "最終承認者", value: "approvalUserName" }
];

export default Vue.extend({
  name: "ApprovalAlert",
  components: {},
  props: {
    affiliationId: {
      type: Number,
      required: true
    },
    employeeId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      header,
      body: [] as PetitionAlert[],
      isMenu: false
    };
  },
  computed: {
    isDisplay: {
      get(): boolean {
        return Array.isArray(this.body) ? this.body.length !== 0 : false;
      }
    }
  },
  watch: {
    employeeId: {
      async handler() {
        await this.getApprovalAlert();
      }
    },
    affiliationId: {
      async handler() {
        await this.getApprovalAlert();
      }
    }
  },
  methods: {
    async getApprovalAlert() {
      const request = new AttendanceAlertRequest({
        employeeId: this.employeeId,
        affiliationId: this.affiliationId
      });
      const result = await this.$post<PetitionAlert[]>(
        this.Paths.alert.approval,
        request
      );
      if (result) {
        console.table(result);
        this.body = result;
      }
    }
  },
  async mounted() {
    await this.getApprovalAlert();
  }
});
