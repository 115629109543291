export class Attendance {
  recordId: number;
  employeeId?: number | null;
  affiliationId?: number | null;
  targetShiftId?: string | null;
  attendanceDate?: number | null;
  startTime?: string | null;
  startTimeUnix?: number | null;
  endTime?: string | null;
  endTimeUnix?: number | null;
  workTime?: string | null;
  inoverTime?: string | null;
  overTime?: string | null;
  breakTime?: string | null;
  morningBreakTime?: string | null;
  morningTime?: string | null;
  midnightBreakTime?: string | null;
  midnightTime?: string | null;
  midnightOverTime?: string | null;
  leaveTime?: string | null;
  lateTime?: string | null;
  opeTime?: string | null;
  workClassificationId?: number | null;
  hadMeal?: string | null;
  lateLeave?: string | null;
  movingDistance?: number | null;
  amount?: number | null;
  extraAmount?: number | null;
  midnightAmount?: number | null;
  holidayAmount?: number | null;
  holidayMidnightAmount?: number | null;
  commutingAmount?: number | null;
  nightShift?: number | null;
  careShu?: string | null;
  careKbn?: string | null;
  timeKbn?: string | null;
  nightGuard?: string | null;
  emergencyFlag?: string | null;
  midnightTel?: string | null;
  assistFlag?: string | null;
  otherFlag?: string | null;
  overTimeFlag?: string | null;
  reserveId1?: number | null;
  reserveId2?: number | null;
  reserveId3?: number | null;
  reserveAmount1?: number | null;
  reserveAmount2?: number | null;
  reserveAmount3?: number | null;
  remarks?: string | null;
  isValid?: string | null;
  createUser?: string | null;
  createTs?: number | null;
  updateUser?: string | null;
  updateTs?: number | null;

  constructor(props?: Attendance) {
    if (props) {
      this.recordId = props.recordId;
      this.employeeId = props.employeeId;
      this.affiliationId = props.affiliationId;
      this.targetShiftId = props.targetShiftId;
      this.attendanceDate = props.attendanceDate;
      this.startTime = props.startTime;
      this.startTimeUnix = props.startTimeUnix;
      this.endTime = props.endTime;
      this.endTimeUnix = props.endTimeUnix;
      this.workTime = props.workTime;
      this.inoverTime = props.inoverTime;
      this.overTime = props.overTime;
      this.breakTime = props.breakTime;
      this.morningBreakTime = props.morningBreakTime;
      this.morningTime = props.morningTime;
      this.midnightBreakTime = props.midnightBreakTime;
      this.midnightTime = props.midnightTime;
      this.midnightOverTime = props.midnightOverTime;
      this.leaveTime = props.leaveTime;
      this.lateTime = props.lateTime;
      this.opeTime = props.opeTime;
      this.workClassificationId = props.workClassificationId;
      this.hadMeal = props.hadMeal;
      this.lateLeave = props.lateLeave;
      this.movingDistance = props.movingDistance;
      this.amount = props.amount;
      this.extraAmount = props.extraAmount;
      this.midnightAmount = props.midnightAmount;
      this.holidayAmount = props.holidayAmount;
      this.holidayMidnightAmount = props.holidayMidnightAmount;
      this.commutingAmount = props.commutingAmount;
      this.nightShift = props.nightShift;
      this.careShu = props.careShu;
      this.careKbn = props.careKbn;
      this.timeKbn = props.timeKbn;
      this.nightGuard = props.nightGuard;
      this.emergencyFlag = props.emergencyFlag;
      this.midnightTel = props.midnightTel;
      this.assistFlag = props.assistFlag;
      this.otherFlag = props.otherFlag;
      this.overTimeFlag = props.overTimeFlag;
      this.reserveId1 = props.reserveId1;
      this.reserveId2 = props.reserveId2;
      this.reserveId3 = props.reserveId3;
      this.reserveAmount1 = props.reserveAmount1;
      this.reserveAmount2 = props.reserveAmount2;
      this.reserveAmount3 = props.reserveAmount3;
      this.remarks = props.remarks;
      this.isValid = props.isValid;
      this.createUser = props.createUser;
      this.createTs = props.createTs;
      this.updateUser = props.updateUser;
      this.updateTs = props.updateTs;
    } else {
      this.recordId = 0;
    }
  }
}
