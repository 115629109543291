<template>
  <DialogBase
    visible
    ref="dialog"
    title="パスワード変更"
    icon="mdi-account-key"
    small
  >
    <v-form v-model="valid" ref="form" @submit.prevent="login">
      <div class="text-center">
        <div class="text-center">
          <p class="text-h5">新しいパスワードを入力してください</p>

          <v-text-field
            v-model="password"
            label="新しいパスワード"
            prepend-inner-icon="mdi-lock"
            :append-icon="!eye ? 'mdi-eye' : 'mdi-eye-off'"
            :type="eye ? 'text' : 'password'"
            outlined
            rounded
            dense
            @click:append="eye = !eye"
            :rules="[Rules.Required]"
          ></v-text-field>
          <v-text-field
            v-model="confirmpassword"
            label="新しいパスワード（確認)"
            prepend-inner-icon="mdi-lock"
            :append-icon="!eye ? 'mdi-eye' : 'mdi-eye-off'"
            :type="eye ? 'text' : 'password'"
            outlined
            rounded
            dense
            @click:append="eye = !eye"
            :rules="[Rules.Required]"
          ></v-text-field>
        </div>
      </div>
    </v-form>

    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="close(false)">
        閉じる
      </v-btn>
      <v-btn color="primary" text @click="fix">
        変更
      </v-btn>
    </template>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </DialogBase>
</template>

<script>
// mixins
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";

import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";

export default {
  name: "TemporaryPasswordChangeDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  data() {
    return {
      userincode: "",
      usercode: "",
      password: "",
      confirmpassword: "",
      eye: false,
      overlay: false
    };
  },
  methods: {
    async fix() {
      if (!this.$refs.form.validate()) return;
      console.log("fix");

      if (this.password !== this.confirmpassword) {
        this.$error("入力したパスワードが一致しません。");
        return false;
      }

      try {
        const param = {
          loginId: this.usercode,
          password: this.password,
          authority: "0"
        };
        await this.$put(this.Paths.authinfoTemporary, param);

        this.$info("更新しました。", "パスワード変更");
        this.$store.commit("user/logout");
        this.resolve(true);
        this.$close();
      } catch (e) {
        console.error(e);
        this.$error(e.message);
      }
    },
    close(result) {
      this.$store.commit("user/logout");
      this.resolve(result);
      this.$close();
    }
  },
  created() {
    console.log("created", this.args);
    this.userincode = this.args.userincode;
    this.usercode = this.args.usercode;
  },
  mounted() {
    console.log("mounted", this.args);
  }
};
</script>
