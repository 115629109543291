
import Vue from "vue";
import { AttendanceAlertRequest } from "@/models/alert/AlertRequest";
import { Attendance } from "@/models/transaction/Attendance";
import { DateTime } from "luxon";
import { PetitionAttendanceSearchRequest } from "@/models/petition/PetitionRequest";
import { PetitionShiftSearch } from "@/models/petition/PetitionResponse";
import ChangeWorkResultsApplication from "@/components/petition/form/ChangeWorkResultsApplication.vue";
import { Messages } from "@/message";

export default Vue.extend({
  name: "AttendanceAlert",
  components: { ChangeWorkResultsApplication },
  props: {
    affiliationId: {
      type: Number,
      required: true
    },
    employeeId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      date: DateTime.local(),
      isDialog: false,
      visible: true,
      attendance: new Attendance(),
      shift: undefined as PetitionShiftSearch | undefined,
      Messages
    };
  },
  watch: {
    shift: {
      handler(newValue: PetitionShiftSearch | undefined) {
        if (newValue?.attendanceDate) {
          this.date = DateTime.fromMillis(newValue.attendanceDate);
        }
      }
    },
    date: {
      async handler(newValue: DateTime) {
        const date = newValue.toISODate();
        if (date) {
          const request = new PetitionAttendanceSearchRequest({
            affiliationId: this.affiliationId,
            employeeId: this.employeeId,
            attendanceDate: date
          });
          const result = await this.$post<Attendance[]>(
            this.Paths.petition.search.attendance,
            request
          );
          if (result && result.length !== 0) {
            this.attendance = result[0];
          }
        }
      }
    },
    isDialog: {
      handler(newValue: boolean) {
        // 事業所選択の変更時
        console.log("isDialog", newValue);
        this.$emit("alertdialog", newValue);
      }
    },
    employeeId: {
      async handler() {
        await this.getPetitionShiftSearch();
      }
    },
    affiliationId: {
      async handler() {
        await this.getPetitionShiftSearch();
      }
    }
  },
  methods: {
    async getPetitionShiftSearch() {
      const request = new AttendanceAlertRequest({
        employeeId: this.employeeId,
        affiliationId: this.affiliationId
      });
      this.shift = await this.$post<PetitionShiftSearch>(
        this.Paths.alert.attendance,
        request
      );
    },
    async onSubmit() {
      const form = this.$refs.form as any;
      const result = await form.onSubmit();
      if (result) {
        this.isDialog = false;
        await this.getPetitionShiftSearch();
      }
    }
  },
  async mounted() {
    console.log(this.employeeId, this.affiliationId);
    await this.getPetitionShiftSearch();
  }
});
