<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    fullscreen
    midium
    close
    headerButtonDisp
    @clickClose="close(false)"
  >
    <div>
      <!-- <v-card class="pa-5" flat outlined>
        <DatePicker
          v-model="date"
          label="Picker in menu"
          prepend-inner-icon="mdi-calendar"
          type="month"
          outlined
          next
          previous
        />

        <v-card-actions>
          <v-spacer></v-spacer>
          <Btn color="info" icon="file-import" @click="getData(true)">取得</Btn>
        </v-card-actions>
      </v-card> -->
      <v-card class="mt-5" v-if="!$isEmpty(cols)" flat outlined>
        <v-toolbar class="mb-2" flat>
          <v-toolbar-title>
            <h3 class="headline">
              勤務実績
            </h3></v-toolbar-title
          >
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text>
          <vue-good-table
            ref="table"
            :columns="cols"
            :rows="rows"
            :select-options="options.select"
            @on-selected-rows-change="onChange"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'remarks'">
                <v-chip
                  class="mx-1"
                  small
                  v-for="remark in props.row.remarks"
                  :key="remark"
                >
                  {{ remark }}
                </v-chip>
              </span>
            </template>
          </vue-good-table>
        </v-card-text>
      </v-card>
    </div>
  </DialogBase>
</template>

<script>
// mixins
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";

const DATE = new Date();

export default {
  name: "AttendanceDetails",
  mixins: [Common, Dialog, Forms, Api],
  components: {
    VueGoodTable
  },
  data: () => ({
    menu: false,
    date: `${DATE.getFullYear()}-${DATE.getMonth() + 1}-${DATE.getDate()}`,
    records: [],
    isConfirm: false,
    // confirm: false,
    attendances: { rows: [], columns: [] },
    summaries: { rows: [], columns: [] },
    total: { rows: [], columns: [] },
    cols: [],
    rows: [],
    summary: [],
    options: {
      select: {
        enabled: true,
        selectOnCheckboxOnly: true,
        disableSelectInfo: true
      },
      search: { enabled: true }
    },
    selected: []
  }),
  computed: {
    Title() {
      return "勤務実績";
    },
    SummaryHeader() {
      return null;
    },
    Year() {
      return !this.date ? undefined : parseInt(this.date.split("-")[0]);
    },
    Month() {
      return !this.date ? undefined : parseInt(this.date.split("-")[1]);
    },
    Day() {
      if (!this.date) return undefined;
      const split = this.date.split("-");

      return split.length < 2 ? undefined : parseInt(split[2]);
    },
    DateString() {
      return this.Year && this.Month
        ? `${this.Year}年 ${this.Month}月`
        : undefined;
    }
  },
  methods: {
    setCalendar(previous) {
      const date = new Date(this.date);
      if (this.select === "office") date.setDate(date.getDate() + 1);
      else date.setMonth(date.getMonth() + (previous ? -1 : 1));

      this.date = `${date.getFullYear()}-${date.getMonth() +
        1}-${date.getDate()}`;
    },
    async setDate() {
      return null;
    },
    close(result) {
      this.$store.commit("user/logout");
      this.resolve(result);
      this.$close();
    },
    onChange(e) {
      console.log(e);
      this.selected = e.selectedRows;
    }
  },
  created() {
    console.log("created", this.args);
    const data = this.args;
    const headers = data.headers.map(header => ({
      label: header.text,
      field: header.value,
      sortable: !["remarks"].includes(header.value)
    }));
    headers.push({ label: "操作", field: "actions", sortable: false });

    const date = headers.find(col => col.field === "date");
    if (date) {
      date.type = "date";
      date.dateInputFormat = "T"; //"yyyy-MM-dd"; // expects 2018-03-16
      date.dateOutputFormat = "M 月 dd 日"; // outputs Mar 16th 2018
    }

    this.cols = headers;
    this.rows = data.body;
  },
  mounted() {
    console.log("mounted", this.args);
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

table th:first-child {
  border-radius: 5px 0 0 0;
}

table th:last-child {
  border-radius: 0 5px 0 0;
  border-right: 1px solid #3c6690;
}

table th {
  text-align: center;
  color: white;
  background: linear-gradient(#829ebc, #225588);
  border-left: 1px solid #3c6690;
  border-top: 1px solid #3c6690;
  border-bottom: 1px solid #3c6690;
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.3) inset;
  width: 10%;
  padding: 10px 0;
}

table td {
  text-align: center;
  border-left: 1px solid #a8b7c5;
  border-bottom: 1px solid #a8b7c5;
  border-top: none;
  box-shadow: 0px -3px 5px 1px #eee inset;
  width: 10%;
  padding: 10px 0;
}

table td:last-child {
  border-right: 1px solid #a8b7c5;
}

table tr:last-child td:first-child {
  border-radius: 0 0 0 5px;
}

table tr:last-child td:last-child {
  border-radius: 0 0 5px 0;
}
</style>
