export class ShiftPattern {
  shiftPatternId: number;
  affiliationId: number;
  shiftPatternName: string;
  abbreviation: string;
  startTime: string;
  endTime: string;
  breakTime: string;
  morningBreakTime: string;
  midnightBreakTime: string;
  daytimeActiveHour: string;
  midnightTimeFlag = "0";
  overTimeFlag = "0";
  workClassificationId = 0;
  nightShiftFlag = "0";
  resultPriorityFlag = "0";
  nightGuardFlag = "0";
  enterAfterNightShiftFlag = "0";
  nightTelFlag = "0";
  timeZone = "0";
  patternOrder = 9999;
  unitPrice = 0;
  isValid = "1";
  createUser?: string | null;
  createTs?: number | null;
  updateUser?: string | null;
  updateTs?: number | null;

  constructor({
    shiftPatternId,
    affiliationId,
    shiftPatternName,
    abbreviation,
    startTime,
    endTime,
    breakTime,
    morningBreakTime,
    midnightBreakTime,
    daytimeActiveHour,
    midnightTimeFlag = "0",
    overTimeFlag = "0",
    workClassificationId = 0,
    nightShiftFlag = "0",
    resultPriorityFlag = "0",
    nightGuardFlag = "0",
    enterAfterNightShiftFlag = "0",
    nightTelFlag = "0",
    timeZone = "0",
    patternOrder = 9999,
    unitPrice = 0,
    isValid = "1",
    createUser,
    createTs,
    updateUser,
    updateTs
  }: {
    shiftPatternId: number;
    affiliationId: number;
    shiftPatternName: string;
    abbreviation: string;
    startTime: string;
    endTime: string;
    breakTime: string;
    morningBreakTime: string;
    midnightBreakTime: string;
    daytimeActiveHour: string;
    midnightTimeFlag?: string;
    overTimeFlag?: string;
    workClassificationId?: number;
    nightShiftFlag?: string;
    resultPriorityFlag?: string;
    nightGuardFlag?: string;
    enterAfterNightShiftFlag?: string;
    nightTelFlag?: string;
    timeZone?: string;
    patternOrder?: number;
    unitPrice?: number;
    isValid?: string;
    createUser?: string | null;
    createTs?: number | null;
    updateUser?: string | null;
    updateTs?: number | null;
  }) {
    this.shiftPatternId = shiftPatternId;
    this.affiliationId = affiliationId;
    this.shiftPatternName = shiftPatternName;
    this.abbreviation = abbreviation;
    this.startTime = startTime;
    this.endTime = endTime;
    this.breakTime = breakTime;
    this.morningBreakTime = morningBreakTime;
    this.midnightBreakTime = midnightBreakTime;
    this.daytimeActiveHour = daytimeActiveHour;
    this.midnightTimeFlag = midnightTimeFlag;
    this.overTimeFlag = overTimeFlag;
    this.workClassificationId = workClassificationId;
    this.nightShiftFlag = nightShiftFlag;
    this.resultPriorityFlag = resultPriorityFlag;
    this.nightGuardFlag = nightGuardFlag;
    this.enterAfterNightShiftFlag = enterAfterNightShiftFlag;
    this.nightTelFlag = nightTelFlag;
    this.timeZone = timeZone;
    this.patternOrder = patternOrder;
    this.unitPrice = unitPrice;
    this.isValid = isValid;
    this.createUser = createUser;
    this.createTs = createTs;
    this.updateUser = updateUser;
    this.updateTs = updateTs;
  }
}
