export class OverworkFlowDat {
  recordId: number;
  requestDate?: string | null;
  employeeId?: number | null;
  affiliationId?: number | null;
  shiftId?: string | null;
  overtimeWorkDate?: string | null;
  overtimeWorkEnd?: string | null;
  overtimeWorkBreak?: string | null;
  reason?: string | null;
  remarks?: string | null;
  isOverwork?: string;
  startTimeStamp?: string;
  endTimeStamp?: string | null;
  latestApprovalLevel?: number | null;
  latestApprovalFlag?: string | null;
  latestApprovalDate?: string | null;

  constructor(props: OverworkFlowDat) {
    this.recordId = props.recordId;
    this.requestDate = props.requestDate;
    this.employeeId = props.employeeId;
    this.affiliationId = props.affiliationId;
    this.shiftId = props.shiftId;
    this.overtimeWorkDate = props.overtimeWorkDate;
    this.overtimeWorkEnd = props.overtimeWorkEnd;
    this.overtimeWorkBreak = props.overtimeWorkBreak;
    this.reason = props.reason;
    this.remarks = props.remarks;
    this.isOverwork = props.isOverwork;
    this.endTimeStamp = props.endTimeStamp;
    this.latestApprovalLevel = props.latestApprovalLevel;
    this.latestApprovalFlag = props.latestApprovalFlag;
    this.latestApprovalDate = props.latestApprovalDate;
  }
}
